

const ar = {
  sellStep: "الخطوة",
  sellStepName: "وضع البيع وموقع القطعة",
  sellTitle: "يرجى مراجعة جميع التفاصيل",
  sellItemLocation: "موقع القطعة",
  sellSellingMode: "وضع البيع",
  sellEditLocation: "تعديل الموقع والوضع",
  sellGender: "الجنس",
  sellBrand: "العلامة التجارية",
  sellsProductType: 'الفئة',
  sellAccessories: "الملحقات",
  sellOriginalPackaging: "التغليف الأصلي",
  sellCondition: "الحالة",
  sellOtherInclusions: "مرفقات أخرى",
  sellDescription: "الوصف",
  sellProofofAuthenticity: "إثبات الأصالة",
  sellReceipts: "الإيصالات",
  sellCertificate: "الشهادات",
  sellSerialNumber: " الأرقام التسلسلية",
  sellEditItemDetails: "تعديل تفاصيل القطعة",
  sellEditPhotos: "تعديل الصور",
  sellTipsTitle: "شكراً لك على تقديم قِطعتك",
  sellTipsInfo:
    "لتقديم تثمينٍ دقيق، سيقوم فريقنا بمراجعة قِطعتك والتحقُّق من أصالتها وتصنيفها بناءً على المعلومات المقدَّمة في الطلب. تحتفظ فالكونز بالحقِّ الحصريِّ في تحديد سعر قطعتك بناءً على المعلومات التفصيلية.",
  sellBackHome: "العودة للصفحة الرئيسة",
  sellAddItem: "إضافة قطعة أخرى",
  sellStep1: "وضع البيع وموقع القطعة",
  sellStep2: "تفاصيل القِطعة",
  sellStep3: "إثبات الأصالة",
  sellStep4: "حمِّل الصور",
  sellItemStep1: "التثمين الأوَّلي",
  sellItemStep2: "تفاصيل الشحن",
  sellItemStep3: "إدراج القطعة والربح",
  sellsMainTitle: "ابدأ البيع على فالكونز",
  selectGender:"اختر فئة الجنس للقِطعة",
  selectCategory:"اختر فئة القِطعة",
  selectAddDetails:"أضف معلومات القِطعة",
  selectBrand:"اسم العلامة التجارية",
  selectProduct:"اسم العلامة التجارية",
  placeholderSelect:"اكتب هنا",
  sellUploadAuthenticity:"حمِّل إثبات الأصالة",
  sellUploadPhoto:"حمِّل الصور",
  sellUploadFileText:" (JPG, PNG, PDF, HEIC)",
  sellUploadFileDescription1:"قم بتضمين شهادات الأصالة، إن وُجدت.",
  sellUploadFileDescription2:"قم بتضمين إيصالات الشراء الأصلية أو إثبات الشراء، إن وُجدت.",
  sellUploadFileDescription3:"تأكَّد من أنَّ الأرقام التسلسلية أو علامات التعريف مرئية في الصور.",
  sellSubmitAdd:"أضف قطعة جديدة",
  sellSubmit:"تأكيد",
  sellModalTitle:"تم اكتمال طلبك",
  sellModalInfo:"بناءً على المعلومات المقدَّمة، سيقوم فريقنا بمراجعة القِطعة والتحقُّق من أصالتها، ثم سيقدم سعراً للموافقة عليه خلال 48 ساعة.",
  sellModalButton:"عرض القطعة المدرَجة",
  sellThankInfoTitle:"شكراً لك على إدراج قِطعتك",
};
const en = {
  sellStep: "STEP",
  sellStepName: "Proof of Authenticity",
  sellTitle: "PLEASE REVIEW ALL DETAILS",
  sellItemLocation: "Item location",
  sellSellingMode: "Selling mode",
  sellEditLocation: "Edit Location & Mode",
  sellGender: "Gender",
  sellBrand: "Brand",
  sellsProductType: "Category",
  sellAccessories: "Accessories",
  sellOriginalPackaging: "Original Packaging",
  sellCondition: "Condition",
  sellOtherInclusions: "Other Inclusions",
  sellDescription: "Description",
  sellProofofAuthenticity: "Proof of Authenticity",
  sellReceipts: "Receipts",
  sellCertificate: "Certificate",
  sellSerialNumber: "Serial number",
  sellEditItemDetails: "Edit Item Details",
  sellEditPhotos: "Edit Photos",
  sellTipsTitle: "THANK YOU FOR SUBMITTING YOUR PIECE",
  sellTipsInfo:
    "Our team will review, authenticate, and grade your submission based on the provided information. Falcons retains the exclusive right to determine the price of your piece based on the detailed information.",
  sellBackHome: "Back to homepage",
  sellAddItem: "Add another item",
  sellStep1: "Location & Mode",
  sellStep2: "Item Details",
  sellStep3: "Proof of Authenticity",
  sellStep4: "Upload your Photos",
  sellItemStep1: "Preliminary Quotations",
  sellItemStep2: "Shipping Details",
  sellItemStep3: "Listing & Earn",
  sellsMainTitle: "Start Selling on Falcons", 
  selectGender: "Select the item's gender category",
  selectCategory: "Select the item category",
  selectAddDetails: "Add the item details",
  selectBrand: "Brand Name",
  selectProduct:"Product Name",
  placeholderSelect:"Insert Text here",
  sellUploadAuthenticity:"Upload the proof of authenticity",
  sellUploadPhoto:"Upload  your Photos",
  sellUploadFileText:" (JPG, PNG, PDF, HEIC)",
  sellUploadFileDescription1:"Include certificate of authenticity, if available.",
  sellUploadFileDescription2:"Provide original purchase receipts or proof of purchase.",
  sellUploadFileDescription3:"Ensure that any serial numbers or identifying marks are visible in the photos.",
  sellSubmit:"SUBMIT",
  sellSubmitAdd:" ADD ANOTHER ITEM",
  sellModalTitle:"Your Submission Is Complete",
  sellModalInfo:"Our team will review, authenticate and submit a price for approvals based on the information provided within 48 hours.",
  sellModalButton: "VIEW YOUR LISTING",
  sellThankInfoTitle:"Thank you for selling your item with us!",
};

export { ar, en };
