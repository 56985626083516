const ar = {
    lendTitle: "منصة تأجير الأزياء",
    lendSubTitle: "استعِر أرقى القِطَع دوماً بلا حدود",
    lendQuestionTitle: "كيف يتم ذلك؟",
    lendQuestionMem: "اختر عضويتك",
    lendQuestionMemAns: `اختر فئة العضوية الخاصة بك لاستكشاف مجموعتنا الحصرية واختر القِطعة التي ترغب في استئجارها.`,
    lendQuestionCheck: "الدفع والتوصيل",
    lendQuestionCheckAns: `بمجرد تأكيد عضويتك والقِطعة التي ترغب في استئجارها، سنضمن لك توصيلها بسرعة مباشرة إلى باب منزلك.`,
    lendQuestionTime: "استمتع بميزة استبدال شهرية",
    lendQuestionTimeAns: `بعد شهر من الاستئجار، استمتع بخيار استبدال اختيارك بقطعة أخرى فاخرة.`,
    lendTierTitle: "اختر عضويتك في المنصة",
    lendCurrency: "AED",
    lendMonthly: "Monthly",
    lendQuaterly: "Quaterly",
    lendSelect: "Select",
    lendAccess: "Access",
    lendGold: "Gold",
    lendPlatinum: "Platinum",
    lendDiamond: "Diamond",
    lendButton:
      "هذه الخدمة متاحة حصرياً للمقيمين في دولة الإمارات العربية المتحدة.",
    lendGoodsTitle: "استكشف مجموعتنا",
    lendGoodsTitleAll: "عرض الكل",
    lendGoodsFaq: "الأسئلة الشائعة",
    lendSave: "وفر",
    lendUpGrade: "ترقية إلى",
    lendSelectInfo: "اختر الفئة التي تناسبك",
    lengdingComingSoon:'قريباً',
    lenfMembership: "العضوية الحالية",
    lendPause: "العضوية متوقفة مؤقتاً",
    lendAssess: "وصول إلى",
    lendEnjoy: "استمتع",
    lendPer: "شهرياً",
    lendInitiationFee: "رسوم بدء العضوية",
    LendmemberShip: "العضوية",
    LendStatus: "الحالة",
    LendSwap: "الاستبدالات المتبقية",
    LendSwapHistory: "سجلُّ الاستبدالات",
    LendSubscription: "تاريخ الاشتراك",
    LendPayment: "تفاصيل الدفع",
    LendVerify: "تفاصيل التحقُّق",
    LendHistoryProduct: "المنتَج",
    LendHistoryDelivery: "حالة التوصيل",
    LendHistoryReturn: "حالة الإرجاع",
    LendHistoryTableDelivery: "حالة التوصيل",
    LendHistoryTableReturnOn: "حالة الإرجاع",
    LendHistoryTableReturn: "إرجاع القِطعة",
    LendSubscriptionTableProduct: "المنتَج",
    LendSubscriptionTableDate: "التاريخ",
    LendSubscriptionTableAmount: "الكمية",
    LendSubscriptionTableMethod: "الطريقة",
    LendMemberUpgrade: "ترقية",
    LendMemberPause: "إيقاف مؤقت",
    LendMemberReactivate: "إعادة تنشيط",
    LendMemberCancel: "إلغاء",
    LendNextPay: "تاريخ الاستحقاق التالي هو",
    LendNextCancel: "اشتراكك سينتهي في",
    LendNextSwap: "الاستبدال التالي في",
    LendBecomeMember: "انضم إلى العضوية",
    LendNolevel: "انضم إلى المنصة!",
    LendLevelText: "فعّل عضويتك اليوم.",
    LendMemberHistory: "سجلُّ الاستبدالات",
    LendMemberSubscription: "تاريخ الاشتراك",
    LendMemberPayment: "تفاصيل الدفع",
    LendMemberVerify: "تفاصيل التحقُّق",
    LendCancelInfo1: `لإلغاء اشتراكك، يرجى إرسال بريد إلكتروني لنا على `,
    LendCancelInfo2: ` حتى نتمكن من معالجة إلغاء اشتراكك وضمان عدم فرض أي رسوم إضافية عليك.`,
    LendCancelTitle: "إلغاء العضوية",
    LendPauseTitle: "إيقاف مؤقت للعضوية",
    LendPauseInfo1: `لإيقاف اشتراكك مؤقتًا، يُرجى إرسال بريد إلكتروني إلى`,
    LendPauseInfo2: `، وسنعمل على معالجة طلبك لضمان عدم فرض أي رسوم خلال فترة الإيقاف.`,
    LendReactiveInfo1: `لإعادة تنشيط اشتراكك، يُرجى إرسال بريد إلكتروني إلى`,
    LendReactiveInfo2: `، وسنعمل على معالجة طلبك لاستئناف عضويتك بسلاسة.`,
    LendBack: "عودة",
    LendDownLevel: "تخفيض فئة العضوية غير متاح",
    LendUnUpgrade:
      "ترقية العضوية متاحةٌ ضمن دورة الفوترة نفسها: من شهرية إلى شهرية أو ربع سنوية إلى ربع سنوية",
    LendInactive: "إلغاء العضوية",
    LendUpdate: "تحديث",
    LendCardUpdate: "تحديث",
    LendCardCancel: "إلغاء",
    LendUpdateCard: "تم تحديث معلومات بطاقة الائتمان بنجاح.",
    LendUpdateCardError: "تعذّر تحديث معلومات بطاقة الائتمان.",
    LendHistoryNoData: "استأجر أول قطعة لك لبدء الاستبدال.",
    LendViewAll: "عرض الكل",
  };
  const en = {
    lendTitle: "Lending Club",
    lendSubTitle: "Borrow endlessly on rotation.",
    lendQuestionTitle: "How does it work?",
    lendQuestionMem: "Select Your Membership",
    lendQuestionMemAns: `Select your membership tier to explore our exclusive collection and choose the item you'd like to rent.`,
    lendQuestionCheck: "Checkout & Delivery",
    lendQuestionCheckAns: `Once your membership and item to rent is confirmed, we will ensure its swift delivery directly to your doorstep.`,
    lendQuestionTime: "Enjoy Monthly Swaps",
    lendQuestionTimeAns: `After a month of renting, enjoy the option of swapping your selection for another exquisite piece.`,
    lendTierTitle: "Choose your Club Membership",
    lendCurrency: "AED",
    lendMonthly: "Monthly",
    lendQuaterly: "Quaterly",
    lendSelect: "Select",
    lendAccess: "Access",
    lendGold: "Gold",
    lendPlatinum: "Platinum",
    lendDiamond: "Diamond",
    lendButton: "This service is exclusively for the residents of UAE.",
    lendGoodsTitle: "EXPLORE OUR COLLECTION",
    lendGoodsFaq: "Frequently Asked Questions",
    lendGoodsTitleAll: "View All",
    lendFaq1: "What is Lending Club?",
    lendFaq1Ans:
      "Lending Club is a peer-to-peer lending platform that allows individuals to borrow money from each other on a fixed-term basis. It offers a wide range of loans, including personal loans, car loans, and student loans, and is available in over 100 countries.",
    lendFaq2: "How does Lending Club work?",
    lendSave: "SAVE",
    lendUpGrade: "Upgrade to",
    lendSelectInfo: "Select Your Tier",
    lengdingComingSoon:'Coming Soon',
    lenfMembership: "Current Membership",
    lendPause: "Membership Paused",
    lendAssess: "Access",
    lendEnjoy: "Enjoy",
    lendPer: "per month",
    lendInitiationFee: "Initiation Fee",
    LendmemberShip: "MEMBERSHIP",
    LendStatus: "STATUS",
    LendSwap: "SWAP LEFT",
    LendMemberHistory: "Swap History",
    LendSwapHistory: "SWAP HISTORY",
    LendSubscription: "SUBSCRIPTION HISTORY",
    LendMemberSubscription: "Subscription History",
    LendPayment: "PAYMENT DETAILS",
    LendMemberPayment: "Payment Details",
    LendVerify: "VERIFICATION DETAILS",
    LendMemberVerify: "Verification Details",
    LendHistoryProduct: "Product",
    LendHistoryDelivery: "Delivery Status",
    LendHistoryReturn: "Return Status",
    LendHistoryTableDelivery: "Delivered on",
    LendHistoryTableReturnOn: "Returned on",
    LendHistoryTableReturn: "Return Item",
    LendSubscriptionTableProduct: "Product",
    LendSubscriptionTableDate: "Date",
    LendSubscriptionTableAmount: "Amount",
    LendSubscriptionTableMethod: "Method",
    LendMemberUpgrade: "UPGRADE",
    LendMemberPause: "PAUSE",
    LendMemberReactivate: "REACTIVATE",
    LendMemberCancel: "CANCEL",
    LendNextPay: "Next due date at",
    LendNextCancel: "Your subscription ends on",
    LendNextSwap: "Next swaps at ",
    LendBecomeMember: "BECOME A MEMBER",
    LendNolevel: "Join the Club!",
    LendLevelText: "Activate your membership today.",
    LendCancelInfo1: `To cancel your subscription, please email us at `,
    LendCancelInfo2: ` so we can process your cancellation to guarantee you'll no longer be charged for this subscription.`,
    LendCancelTitle: "CANCEL MEMBERSHIP",
    LendPauseTitle: "PAUSE MEMBERSHIP",
    LendPauseInfo1: `To pause your subscription, please email us at `,
    LendPauseInfo2: `, and we will process your request to ensure no further charges during the pause period.`,
    LendReactiveInfo1: `To reactivate your subscription, please email us at`,
    LendReactiveInfo2: `, and we will process your request to resume your membership seamlessly.`,
    LendBack: "BACK",
    LendDownLevel: "Downgrading Membership Not Available",
    LendUnUpgrade:
      "Upgrades are available within the same billing cycle: monthly to monthly or quarterly to quarterly.",
    LendInactive: "Membership Cancellation",
    LendUpdate: "Update",
    LendCardUpdate: "UPDATE",
    LendCardCancel: "CANCEL",
    LendUpdateCard: "Credit card information updated successfully.",
    LendUpdateCardError: "Credit card information update failed.",
    LendHistoryNoData: "Rent your first item to start swapping.",
    LendViewAll: "VIEW ALL",
  };
  
  export { ar, en };
  